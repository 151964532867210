<template>
	<div class="letter" v-if="isdiv">
		<!-- 导航栏 -->
		<van-nav-bar
		class="nav_bar_color"
		title="邀请函详情"
		left-text="返回"
		left-arrow
		@click-left="onClickLeft"
		fixed
		z-index="11" 
		:border="false"
		placeholder
		>
		</van-nav-bar>
		<div class="letter_top">
					发送人：{{invitationinfo.username}}<span>{{getDateTime1(invitationinfo.send_time)}}</span>
		</div>

		<div class="invitation">
			<img class="imgbj" :src="serverUrl(imgurl)" mode="">
		</div>

		<!-- 文本组件 -->
		<div class="driveinfo_top">
			{{content[0]}}
			<div><span style="visibility: hidden;">空格</span> {{content[1]}}</div>
			<div><span style="visibility: hidden;">空格</span> {{content[2]}}</div>
			<div><span style="visibility: hidden;">空格</span> {{content[3]}}</div>
		</div>
		
		<!-- 我的反馈 -->
		<div class="feedback">
			<div class="feedback_title">
				我的反馈
			</div>
			
			<div class="feedback_list" v-for="v,index in feedbackinfo" :key="index">
				<div class="feedback_list_title">
					{{v.content}}
				</div>
				<div class="feedback_list_imgs">
					<img src="@/assets/drivroute/icon_weixuanz.png" mode=""><span>是</span>
					<img style="margin-left:140px" src="@/assets/drivroute/icon_weixuanz.png" mode=""><span>否</span>
				</div>
			</div>
		</div>
		
		
		<!-- 公司名称 -->
		<div class="driveinfo_shop">
			<img :src="serverUrl(firminfo.images)" mode="">
			<span>{{firminfo.content[0]}}</span>
		</div>
		
		<!-- 地图组件 -->
		<div v-if="islat" class="driveinfo_map">
			<div class="driveinfo_map_left">
				<Map :center="lat"></Map>
			</div>
			<div class="driveinfo_map_rigth">
				<div class="driveinfo_map_title">
					{{mapinfo.store_name}}
				</div>
				<div class="driveinfo_map_add">
					<img src="@/assets/drivroute/icon_dinwei.png" mode="">
					<span>{{mapinfo.store_address}}</span>
				</div>
				<div class="driveinfo_map_photo">
					<img src="@/assets/drivroute/icon_model.png" mode="">
					<span>{{mapinfo.store_contact}}</span>
				</div>
			</div>
		</div>
		
		
		
		<!-- 个人信息组件 -->
		<div class="driveinfo_footer">
			<van-image class="center_avatar" round :src="serverUrl(userinfo.avatar)" fit="cover"/>
			<span class="driveinfo_footer_name">{{userinfo.bio}}</span>
			<span class="driveinfo_footer_title">您的专属销售顾问{{userinfo.username}}随时为您服务</span>
		</div>
		
	</div>
</template>

<script>
	import Map from '../../components/map.vue'
	export default {
		components: {
			Map

		},
		data() {
			return {
				pageTitle:'离店致谢函',
				modellist:[],
				id:'',
				invitationinfo:{},
				imgurl:'',
				firminfo:{},
				content:'',
				timelist:[],
				userinfo:{},
				scrollTop: 0,
				mapinfo:{},
				lat:[],
				feedbackinfo:[],
				invitation_id:'',
				isshare:false,
				islat:true,
				isdiv:false
			};
		},
		mounted() {
			this.id = this.$route.query.id
			this.getletterinfo()
		},
		computed: {
			
		},
		watch:{
			
		},
		methods: {
			// 时间转化
			getDateTime1(timestamp){
				const date = new Date(timestamp * 1000)
				const Y = date.getFullYear() + '-'
				const M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-'
				const D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' '
				const h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':'
				const m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes())
				const s = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds())
				return Y + M + D + h + m
			},
			async getletterinfo(){
				const {
						data: res
					} = await this.$http.post('/api/scsj/get_invitation_info_1_7_1',{id:this.id})
					if (res.code == 1) {
						this.invitationinfo = res.data.res
						this.imgurl = this.invitationinfo.scsj_invitation_formwork_content[0].images
						this.content = this.invitationinfo.scsj_invitation_formwork_content[1].content
						this.mapinfo = this.invitationinfo.scsj_invitation_formwork_content[4].position
						if(this.mapinfo.store_lng_lat != null && this.mapinfo != null){
							const indexs = this.mapinfo.store_lng_lat.indexOf(',')
							this.lat[0] = parseFloat(this.mapinfo.store_lng_lat.slice(0,indexs)) 
							this.lat[1] = parseFloat(this.mapinfo.store_lng_lat.slice(indexs+1))  
						}else{
							this.islat = false
						} 
						this.userinfo = this.invitationinfo.scsj_invitation_formwork_content[5].userinfo
						this.firminfo = this.invitationinfo.scsj_invitation_formwork_content[3]
						this.feedbackinfo = this.invitationinfo.scsj_invitation_formwork_content[2].feedback
					}
				this.isdiv = true
			}
			
		}
	}
</script>

<style lang="scss" scoped>
.invitation{
	padding: 40px;
	
}
.letter{
	padding-bottom: 230px;
}
.invitationinputs{
		padding: 30px;
		margin-top: 25px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		background: #2D2D2D;
		height: 88px;
		font-size: 32px;
		font-family: HYQiHeiY3-45;
		font-weight: normal;
		color: #ECECEC;
		img{
			width: 32px;
			height: 32px;
		}
	}

.feedback{
	padding: 40px;
	.feedback_title{
		font-size: 36px;
		font-family: HYQiHeiY3-65;
		padding: 20px;
		text-align: center;
		font-weight: normal;
		color: #FFFFFF;
	}
	.feedback_list{
		margin-top: 30px;
		.feedback_list_title{
			font-size: 32px;
			font-family: HYQiHeiY3-55;
			font-weight: normal;
			color: #FFFFFF;
		}
		.feedback_list_imgs{
			padding: 40px 0;
			display: flex;
			align-items: center;
			img{
				width: 48px;
				height: 48px;
			}
			span{
				font-size: 32px;
				font-family: HYQiHeiY3-55;
				font-weight: normal;
				color: #FFFFFF;
				margin-left: 24px;
			}
		}
	}
}

.driveinfo_top{
	margin-top: 20px;
	padding: 30px 40px;
	font-size: 32px;
	font-family: HYQiHeiY3-45;
	font-weight: normal;
	display: flex;
	flex-direction: column;
	color: #FFFFFF;
	line-height: 48px;
	.driveinfo_line{
		margin-top: 42px;
		height: 1px;
		background-color: #919191;
	}
	.input_name{
		margin-top: 25px;
		height: 88px;
		background: #2D2D2D;
		padding: 0 30px;
		font-size: 32px;
		font-family: HYQiHeiY3-45;
		font-weight: normal;
		color: #ECECEC;;
		line-height: 88px;
	}
}
.imgbj{
	width: 670px;
	height: 580px;
}

.driveinfo_footer{
		padding: 40px;
		display: flex;
		align-items: center;
		flex-direction:column;
		.center_avatar{
			width: 120px;
			height: 120px;
			border: 1px solid #FA0037;
			border-radius: 50%;
		}
		.driveinfo_footer_name{
			margin-top: 32px;
			font-size: 40px;
			font-family: HYQiHeiY3-45;
			font-weight: 400;
			color: #FFFFFF;
			line-height: 40px;
		}
		.driveinfo_footer_title{
			margin-top: 32px;
			font-size: 32px;
			color: #BFBFBF;
			line-height: 32px;
		}
	}

.driveinfo_map{
		padding: 40px 32px;
		display: flex;
		.driveinfo_map_left{
			height: 242px;
			width: 242px;
			background-color: #fff;
		}
		.driveinfo_map_rigth{
			width: 350px;
			margin-left: 20px;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			.driveinfo_map_title{
				
				font-size: 32px;
				font-family: HYQiHeiY3-45;
				font-weight: normal;
				color: #ACACAC;
				line-height: 40px;
			}
			.driveinfo_map_photo{
				display: flex;
				img{
					width: 32px;
					height: 28px;
				}
				span{
					width: 350px;
					margin-left: 20px;
					font-size: 32px;
					font-family: HYQiHeiY3-45;
					font-weight: normal;
					color: #ACACAC;
					line-height: 40px;
				}
			}
			.driveinfo_map_add{
				margin-top: 30px;
				display: flex;
				img{
					width: 32px;
					height: 28px;
				}
				span{
					width: 350px;
					overflow:hidden;
					text-overflow:ellipsis;
					display: -webkit-box; 
					-webkit-box-orient: vertical;
					-webkit-line-clamp: 2; 
					margin-left: 20px;
					font-size: 32px;
					font-family: HYQiHeiY3-45;
					font-weight: normal;
					color: #ACACAC;
					line-height: 34px;
				}
			}
		}
}




.driveinfo_shop{
		border: 1px solid #979797;
		padding: 28px;
		margin: 60px 32px 0;
		display: flex;
		align-items: center;
		img{
			height: 144px;
			width: 144px;
		}
		span{
			margin-left: 40px;
			font-size: 36px;
			font-family: HYQiHeiY3-55;
			font-weight: normal;
			color: #FFFFFF;
		}
	}

.user_footer{
	z-index: 2;
	position: fixed;
	bottom: 0;
	width: 100%;
	height: 182px;
	background: #000000;
	text-align: center;
	div{
		width: 670px;
		height: 92px;
		background: #FA0037;
		text-align: center;
		margin: 30px auto 0;
		line-height: 92px;
		font-size: 36px;
		font-family: HYQiHeiY3-55;
		font-weight: normal;
		color: #FFFFFF;
	}
}

.letter_top{
	padding: 40px 28px;
	margin: 20px 40px 10px;
	font-size: 32px;
	font-family: HYQiHeiY3-45;
	background: #2D2D2D;
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-weight: 400;
	color: #ECECEC;
	span{
		font-size: 24px;
		font-family: HYQiHeiY3-45;
		font-weight: 400;
		color: #979797;
	}
}
</style>

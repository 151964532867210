<template>
	<div class="map" id="container">

	</div>
</template>

<script>

export default {
	name: 'MenuCard',
	props: {
		center: Array
	},
	data() {
		return {
			map:null,
		};
	},
	mounted(){
		this.mapInit()
	},
	methods: {
		// 创建地图
		mapInit(e) {
			var center = new window.TMap.LatLng(this.center[1], this.center[0])
			this.map = new window.TMap.Map(document.getElementById("container"), {
						zoom:16,//设置地图缩放级别
						center: center,//设置地图中心点坐标,
						viewMode:'2D',
						draggable: false,               //设置是否可以拖拽
						scrollwheel: false,             //设置是否可以滚动
						disableDoubleClickZoom: false 
				});
			
				this.markerLayer = new window.TMap.MultiMarker({
					map: this.map,  //指定地图容器
					//样式定义
					styles: {
							//创建一个styleId为"myStyle"的样式（styles的子属性名即为styleId）
							"myStyle": new window.TMap.MarkerStyle({ 
									"width": 35,  // 点标记样式宽度（像素）
									"height": 35, // 点标记样式高度（像素）
									// "src": 'map.png',  //图片路径 这里不设置，就会使用默认的样式
									//焦点在图片中的像素位置，一般大头针类似形式的图片以针尖位置做为焦点，圆形点以圆心位置为焦点
									"anchor": { x: 16, y: 32 }  
							}) 
					},
					//点标记数据数组
					geometries: [{
							"id": "1",   //点标记唯一标识，后续如果有删除、修改位置等操作，都需要此id
							"styleId": 'myStyle',  //指定样式id
							"position": center,  //点标记坐标位置
					}]
			})

		}
		
	}
}
</script>

<style lang="scss" scoped>
.map{
	height: 100%;
	width:100%;
	z-index: 1;
}
</style>